import * as React from "react";
import { Link } from "gatsby";

import "../components/404/404.scss";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <>
      <Helmet title={"404 - Game On"} />
      <main className={"notfound-wrapper"}>
        <div>
          <h3>404</h3>
          <h1>page not found</h1>
          <p>
            The page you request is no longer working, or you mispelled the url,
            please try again or{" "}
            <Link to={"/en"}> go back to the homepage.</Link>
          </p>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
